import React, { useState } from 'react';
import { useNavigate, Route } from 'react-router-dom';

import PocketBase from 'pocketbase';
import Input from '../../components/Input';
import ButtonAuth from '../../components/ButtonAuth';
import errorMessages from '../../utils/errorMessages';
import useDeviceType from '../../utils/useDeviceType';

import'./styles.css'
import GoogleButton from "../../components/GoogleButton";
import Divider from '../../components/Divider';


type errorType = {
    field: string,
    message: string
}

type SignIn = {
  email: string,
  password: string,
  passwordConfirm: string
}

const SignIn = ( ) => {
    const deviceType = useDeviceType()
    const navigate = useNavigate();
    const [isError, setIsError] = useState<errorType>({
        field: '',
        message: ''
    })


    const [signIn, setSignIn] = useState<SignIn>({
        email: '',
        password: '',
        passwordConfirm: ''
    })


    const pb = new PocketBase('https://auth.dillo.ar/');

    const handleOnSignInClick = async () => {
        const record = await pb.collection('users').create({
        email: signIn.email.toLowerCase(),
        password: signIn.password,
        passwordConfirm: signIn.passwordConfirm,
        })
        .then( data => {
            setIsError({
                field: '',
                message: ''
            })
            navigate('/');
        })
        .catch( error => {
            const errorData = error.data.data
            if(errorData.email) {
                setIsError({
                    field: 'email',
                    message: errorData.email.code
                })
                return
            }
            if(errorData.password && !errorData.passwordConfirm) {
                setIsError({
                    field: 'password',
                    message: errorData.password.code
                })
                return
            }
            if (errorData.passwordConfirm) {
                setIsError({
                    field: 'passwordConfirm',
                    message: errorData.passwordConfirm.code
                })
                return
            }
            setIsError({
                field: '',
                message: 'error'
            })
        });
    }

    const handleOnOAuthClick = async (provider) => {
        const w = window.open()
        const authData = await pb.collection('users').authWithOAuth2({ provider: provider,  urlCallback: (url) => {
                w.location.href = url
            } });
        navigate('/');
    }

    const handleOnChange = (e) => {
        setSignIn({
            ...signIn,
            [e.target.name]: e.target.value
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleOnSignInClick()
        }
    }

    return (
        <div className='signin-container'>
            <div className='logo-container'>
                <a href="/">
                    <img
                        src="/dillo-brand.png"
                        width='192px'
                        alt='Dillo'
                    />
                </a>
            </div>
            <h1 className="signin-title">Crea una cuenta</h1>
            <div className='form-container'>
                <div className={`${deviceType !== 'mobile' ? 'info-container-desktop' : 'info-container'}`}>
                    <Input
                        isError={isError.field == 'email'}
                        type='text'
                        name='email'
                        handleOnChange={(e) => handleOnChange(e)}
                        value={signIn.email}
                        label='Correo electrónico'
                        handleKeyDown={handleKeyDown}
                        isRequired
                    />
                    <Input
                        isError={isError.field == 'password'}
                        type='password'
                        name='password'
                        handleOnChange={(e) => handleOnChange(e)}
                        value={signIn.password}
                        label='Contraseña'
                        handleKeyDown={handleKeyDown}
                        isRequired
                    />
                    <Input
                        isError={isError.field == 'passwordConfirm'}
                        type='password'
                        name='passwordConfirm'
                        handleOnChange={(e) => handleOnChange(e)}
                        value={signIn.passwordConfirm}
                        label='Confirmar contraseña'
                        handleKeyDown={handleKeyDown}
                        isRequired
                    />
                    <ButtonAuth
                        handleOnClick={() => handleOnSignInClick()}
                        buttonText='Registrar'
                    />
                    {isError.message &&
                        <label className="error-message">
                            {errorMessages[isError.message]}
                        </label>
                    }
                </div>
                <Divider label='o' />
                <div className='oAuth-container'>
                    <GoogleButton
                        buttonImage='/google-logo-search-new-svgrepo-com 1.png'
                        buttonText='Continuar con Google'
                        handleOnClick={() => handleOnOAuthClick('google')}
                    />
                </div>
                <label>¿Ya tienes cuenta? <a href='/' style={{color: '#7FCFF5', textDecoration: 'none'}}>Ingresa</a></label>
            </div>
        </div>
    );
};

export default SignIn;