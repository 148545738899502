import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import GameAlphabet from "./games/gameAlphabet";
import SignIn from './views/SignIn';
import Login from './views/login';
import { validateToken } from './api/api';
import GameSelector from './views/GameSelector';
import GameWords from './games/gameWords';
import StartView from './views/StartView';
import PrivateRoute from './components/PrivateRoute';
import GameAllAlphabet from "./games/gameAllAlphabet.tsx";
import GameWordsMonths from "./games/gameWordsMonths";
import OrganizationSelector from "./views/OrganizationSelector";
import Dashboard from "./views/Dashboard";
import RequestPasswordReset from "./views/RequestPasswordReset";
import ConfirmPasswordReset from "./views/ConfirmPasswordReset";
import GameWordsColors from "./games/gameWordsColors";
import Profile from './views/Profile/index.tsx';

function App() {
    const [isAuth, setIsAuth] = useState(false);
    const [isOrg, setIsOrg] = useState(false);

    useEffect(() => {
        async function refreshToken() {
            const auth = localStorage.getItem('pocketbase_auth');
            if (!auth) {
                setIsAuth(false);
                return;
            }

            const jsonAuth = JSON.parse(auth);
            const organization = jsonAuth?.record?.organization;
            setIsOrg(Boolean(organization)); // Set org based on whether organization exists

            // Validate token
            await validateToken(jsonAuth.token)
                .then((res) => {
                    if (res.token) {
                        setIsAuth(true);
                        localStorage.setItem('pocketbase_auth', JSON.stringify(res));
                        setIsOrg(Boolean(res?.record?.organization)); // Set org based on updated response
                    }

                    if (res.code == 401){
                        setIsAuth(false);
                        localStorage.removeItem('pocketbase_auth');
                        location.reload();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsAuth(false);
                });
        }
        refreshToken();
    }, []);

    return (
        <Router>
            <div className="full-size">
                {/* Routing logic */}
                <Routes>
                    <Route element={<PrivateRoute />}>
                        <Route
                            path="/estadisticas"
                            element={
                                <Dashboard/>
                            }
                        />
                        <Route
                            path="/perfil"
                            element={
                                <Profile/>
                            }
                        />
                        <Route
                            path="/"
                            element={isOrg ? <GameSelector /> : <OrganizationSelector />}
                        />
                        <Route
                            path="/abecedario"
                            element={
                                <StartView
                                    redirectTo='/abecedario/jugar'
                                    text={<>Deberás aprender todo el <span>abecedario</span> </>}
                                />
                            }
                        />
                            <Route
                            path="/abecedario/jugar"
                            element={<GameAllAlphabet />}
                        />
                        <Route
                            path="/abecedario-palabras"
                            element={
                                <StartView
                                    redirectTo='/abecedario-palabras/jugar'
                                    text={<>Deberás <span>deletrear 5 palabras</span> aleatorias</>}
                                />
                            }
                        />
                        <Route
                            path="/abecedario-palabras/jugar"
                            element={<GameAlphabet />}
                        />
                        <Route
                            path="/meses"
                            element={
                                <StartView
                                    redirectTo='/meses/jugar'
                                    text={<>Deberás señar todos los <span>meses</span> del año</>}
                                />
                            }
                        />
                        <Route
                            path="/meses/jugar"
                            element={<GameWordsMonths />}
                        />
                        <Route
                            path="/colores"
                            element={
                                <StartView
                                    redirectTo='/colores/jugar'
                                    text={<>Deberás señar los <span>colores</span> </>}
                                />
                            }
                        />
                        <Route
                            path="/colores/jugar"
                            element={<GameWordsColors />}
                        />
                        <Route
                            path="/vocabulario"
                            element={
                                <StartView
                                    redirectTo='/vocabulario/jugar'
                                    text={<>Deberás <span>señar 5 palabras</span> aleatorias</>}
                                />
                            }
                        />
                        <Route
                            path="/vocabulario/jugar"
                            element={<GameWords />}
                        />
                        <Route
                            path="/frases"
                            element={
                                <StartView
                                    redirectTo='/frases/jugar'
                                    text={<>Deberás <span>señar 5 palabras</span> aleatorias</>}
                                />
                            }
                        />
                        <Route
                            path="/frases/jugar"
                            element={<GameWords />}
                        />
                    </Route>

                    <Route
                        path="/ingresar"
                        element={<Login setIsAuth={setIsAuth} />}
                    />
                    <Route
                        path="/registrar"
                        element={<SignIn />}
                    />
                    <Route
                        path="/restablecer-contraseña"
                        element={<RequestPasswordReset />}
                    />
                    <Route
                        path="/confirmar-contraseña"
                        element={<ConfirmPasswordReset />}
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
