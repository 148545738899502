import './styles.css';
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper } from '@mui/material';

const StatisticsTable = ({ records }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const currentRecords = records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper style={{ width: '80%' }} elevation={0} className="table-container">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-cell table-header">Email</TableCell>
                            <TableCell className="table-cell table-header">Abecedario</TableCell>
                            <TableCell className="table-cell table-header">Abecedario palabras</TableCell>
                            <TableCell className="table-cell table-header">Meses</TableCell>
                            <TableCell className="table-cell table-header">Vocabulario</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentRecords.map((record) => (
                            <TableRow key={record.id}>
                                <TableCell className="table-cell">{record.email}</TableCell>
                                <TableCell className="table-cell">{record.abecedario}</TableCell>
                                <TableCell className="table-cell">{record.abecedario_palabras}</TableCell>
                                <TableCell className="table-cell">{record.meses}</TableCell>
                                <TableCell className="table-cell">{record.vocabulario}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={records.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20]}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
        </Paper>
    );
};

export default StatisticsTable;