import useDeviceType from "../../utils/useDeviceType";
import { Button } from "../../components/Button";
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';

import './styles.css'
import {useEffect} from "react";
import MenuModal from "../../components/MenuModal";

const EndView = ({redirectTo, text}) => {
    const navigate = useNavigate();
    const deviceType = useDeviceType()

    useEffect(() => {
        async function sendStatistics() {
            const pb = new PocketBase('https://auth.dillo.ar');

            const record = await pb.collection('statistics').getFullList();

            const updateFields = {
                "/vocabulario": "vocabulario",
                "/abecedario": "abecedario",
                "/abecedario-palabras": "abecedario_palabras",
                "/meses": "meses",
                "/colores": "colores"
            };

            if (updateFields[redirectTo]) {
                record[0][updateFields[redirectTo]] += 1;
            }

            await pb.collection('statistics').update(record[0].id, record[0]);
        }
        sendStatistics();
    }, []);

    return(
        <div  className="element-container">
            <MenuModal/>
            <div>
                <a href="/">
                    <img
                        src="/dillo-brand.png"
                        width='211px'
                        alt='Dillo'
                    />
                </a>
            </div>
            <div className="start-game-text">{text}</div>
            <div className={`button-container ${deviceType !== 'mobile' && 'desktop-container'}`}>
                <Button text='Continuar jugando' onClick={() => navigate(redirectTo)} buttonType={'primary'} />
                <Button text='Terminar' onClick={() => navigate('/')} buttonType={'secondary'} />
            </div>
        </div>
    )
}

export default EndView